import React from 'react';

import { Link } from 'gatsby';

import styles from './style.module.scss';

const Page404 = () => (
  <section className={styles.root}>
    <span className={styles.text}>Page not found</span>
    <Link to="/" className={styles.link}>
      Go to home page
    </Link>
  </section>
);

export default Page404;
